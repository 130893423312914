import { AnyAction } from 'redux'

export interface ImageInsuranceType {
  type: string
  image: string
}
export interface ImageCarInsuranceType {
  vehicleFrontImageUrl: string | undefined
  vehicleFrontImageUrlTagUpdate: number | undefined
  vehiclePassengerImageUrl: string | undefined
  vehiclePassengerImageUrlTagUpdate: number | undefined
  vehicleDriverImageUrl: string | undefined
  vehicleDriverImageUrlTagUpdate: number | undefined
  vehicleBackImageUrl: string | undefined
  vehicleBackImageUrlTagUpdate: number | undefined
  cardOwnerImageUrl: string | undefined
  cardOwnerImageUrlTagUpdate: number | undefined
  carRegisterBookImageUrl: string | undefined
  carRegisterBookImageUrlTagUpdate: number | undefined
  ownerConsentImageUrl: string | undefined
  ownerConsentImageUrlTagUpdate: number | undefined
  insuranceDocumentUrl: string | undefined
  insuranceDocumentUrlTagUpdate: number
}

export default (state: ImageCarInsuranceType[] = [], action: AnyAction) => {
  switch (action.type) {
    case 'SET_IMAGE_CAR':
      return action.payload
    default:
      return state
  }
}
