import { AnyAction } from 'redux'

export default (state: any[] = [], action: AnyAction) => {
  switch (action.type) {
    case 'SET_CONTRACT_DATA':
      return action.payload
    default:
      return state
  }
}
