import moment from 'moment'
import { AnyAction } from 'redux'

export interface RecordType {
  _id: string
  createdAt: string
  updatedAt: string
  __v: number
  ownerId: number
  availableContactTime: string[]
  term1: boolean
  term2: boolean
  idCardURL: string
  houseURL: string
  vehicleBrand: string
  vehicleModel: string
  vehicleYear: number
  vehicleType: string
  transmissionType: 'auto' | 'manual' | ''
  frontURL: string
  backURL: string
  leftURL: string
  rightURL: string
  vehicleBookURL: string
  serviceType: 'carsharing' | 'car-rental' | ''
  packageType: 'starter' | 'verified-starter' | 'verified-pro' | ''
  insuranceApplyType: 'daily' | 'yearly' | ''
  insuranceURL: string
  paymentURL: string
  status:
    | 'incomplete'
    | 'pending'
    | 'action_required'
    | 'verified'
    | 'completed'
    | 'installed'
    | 'unqualified'
  hasInsurance: boolean
  hasParking: boolean
  parkingLat: { $numberDecimal: number }
  parkingLong: { $numberDecimal: number }
  progress: { $numberDecimal: number }
  errorMessage: string

  // REQUIRED ON ADMIN
  // VEHICLE
  keyType: string
  fuelType: string
  licensePlate: string
  vinNo: string
  engineSize: string
  vehicleColor: string
  // INSURANCE
  insuranceTypeCode: number
  insurancePolicyNo: string
  insuranceCompanyNo: string
  insuranceContactNo: string
  insuranceIssueDate: Date
  insuranceExpireDate: Date
  // PARKING
  parkingGeo: string
  parkingStartHour: number
  parkingStopHour: number
  parkingLink: string
  parkingWayFinder: string
  // PAYMENT
  accountNo: string
  accountName: string
  bank: string
  branch: string
}

export const defaultRecord: RecordType = {
  _id: '',
  createdAt: '',
  updatedAt: '',
  __v: 0,
  ownerId: 0,
  availableContactTime: [],
  term1: false,
  term2: false,
  idCardURL: '',
  houseURL: '',
  vehicleBrand: '',
  vehicleModel: '',
  vehicleYear: 0,
  vehicleType: '',
  transmissionType: '',
  frontURL: '',
  backURL: '',
  leftURL: '',
  rightURL: '',
  vehicleBookURL: '',
  serviceType: '',
  packageType: '',
  insuranceApplyType: '',
  insuranceURL: '',
  paymentURL: '',
  status: 'incomplete',
  hasInsurance: false,
  hasParking: false,
  parkingLat: { $numberDecimal: 0 },
  parkingLong: { $numberDecimal: 0 },
  progress: { $numberDecimal: 0 },
  errorMessage: '',

  // REQUIRED ON ADMIN
  // VEHICLE
  keyType: '',
  fuelType: '',
  licensePlate: '',
  vinNo: '',
  engineSize: '',
  vehicleColor: '',
  // INSURANCE
  insuranceTypeCode: 0,
  insurancePolicyNo: '',
  insuranceCompanyNo: '',
  insuranceContactNo: '',
  insuranceIssueDate: new Date(moment().format()),
  insuranceExpireDate: new Date(moment().format()),
  // PARKING
  parkingGeo: '',
  parkingStartHour: 0,
  parkingStopHour: 23,
  parkingLink: '',
  parkingWayFinder: '',
  // PAYMENT
  accountNo: '',
  accountName: '',
  bank: '',
  branch: '',
}

export interface ReactRecordType
  extends Omit<RecordType, 'progress' | 'parkingLat' | 'parkingLong'> {
  name: string
  lastname: string
  email: string
  mobile: string
  cl_number?: string
  birthdate?: string
  address?: string

  progress: number
  parkingLat: number
  parkingLong: number
}

export default (state: RecordType[] = [], action: AnyAction) => {
  switch (action.type) {
    case 'SET_SUBMISSIONS':
      return action.payload

    case 'FILTER_STATUS':
      return state.filter((each: RecordType) => each.status === action.payload)
    default:
      return state
  }
}
